import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Mobile App Wireframe | App Wireframe | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/mobile-app-wireframe/"
    metaDescription="Aimed to assist you in UX ideation, this mobile app wireframe template has everything you need to create your next low-fidelity mobile prototype."
    description="
    h2:A mobile app wireframe template: the key to a streamlined design
    <br/>
    Take your UX ideation game to the next level with Uizard's mobile wireframe template. While Uizard is known as a high-fidelity prototyping tool, more and more people are using Uizard's <a:https://uizard.io/wireframing/>wireframing</a> mode to create compelling low-fidelity wireframes as initial blueprints for their mobile applications.
    <br/>
    h3:A mobile wireframe template framed around purpose and functionality
    <br/>
    Aimed to assist you in UX ideation, this mobile wireframe template has everything you need to create your next low-fidelity mobile prototype. Simply sign up to Uizard for free, navigate to our <a:https://uizard.io/templates/mobile-app-templates/>mobile app design templates</a>, and adapt to suit your own project.
    <br/>
    h3:Turn your low-fidelity wireframes into high-fidelity wireframes in one click
    <br/>
    When you're done with the skeleton of your design, simply disable Wireframe mode in Uizard with one click to turn your low-fidelity wireframes into mockups, instantly reflecting your chosen theme! Sign up to Uizard for free today and revolutionize how you approach digital product UI design.
    "
    pages={[
      "A menu page",
      "A mobile landing page wireframe with fully reusable sections",
      'An "About" page wireframe that features your story and your team ',
      'A "Contact Us" page wireframe with a form, complemented by a placeholder for a pinned map on "Find us here"',
    ]}
    projectCode="RKQ9MqojQ1folOxn34zY"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Mobile App Wireframe: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Mobile App Wireframe: about us page"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Mobile App Wireframe: contact page"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Mobile App Wireframe: menu"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of Mobile App Wireframe template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/mobile-wireframe/mobile-wireframe-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/mobile-wireframe/mobile-wireframe-about.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/mobile-wireframe/mobile-wireframe-contact.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/mobile-wireframe/mobile-wireframe-menu.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/mobile-wireframe/mobile-wireframe-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
